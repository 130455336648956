import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import {
    Box, ProgressBar, ThemeContext,
} from '@saladbob/sassafras';

const styles = createUseStyles({
    Header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        minHeight: '88px',
        zIndex: 2,
        background: ({ bgColor, theme }) => theme.colors[bgColor] || bgColor,
    },
    Body: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 1200,
        padding: '1rem',
    },
    Logo: {
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto',
        '& img': {
            display: 'block',
            width: '100%',
        },
    },
    LogoStep: {
        marginLeft: 'auto',
        marginRight: '32px',
        width: '100%',
        maxWidth: '42px',
        '& img': {
            display: 'block',
            width: '100%',
        },
    },
});

const Header = ({
    logo, logoStep, step, steps, ...props
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, ...props });

    return (
        <div className={classes.Header}>
            <div className={classes.Body}>
                {step === 0 && (
                    <div className={classes.Logo}>
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                )}
                {step > 0 && (
                    <>
                        <div className={classes.LogoStep}>
                            <Link to="/">
                                <img src={logoStep} alt="logo" />
                            </Link>
                        </div>
                        <Box maxWidth="360px" margin="auto auto auto 0">
                            <ProgressBar
                                size="xxs"
                                bgColor="rgba(0, 0, 0, 0.1)"
                                progressColor="primary"
                                progress={step / steps}
                            />
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

Header.propTypes = {
    logo: PropTypes.string,
    logoStep: PropTypes.string,
    step: PropTypes.number,
    steps: PropTypes.number,
    bgColor: PropTypes.string,
    navBgColor: PropTypes.string,
    nav: PropTypes.node,
    navMobile: PropTypes.node,
};

Header.defaultProps = {};

export default Header;
