/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities  */

import React from 'react';

import ContentPage from '../components/ContentPage';

const PersonalLoanNotice = ({ brandName }) => (
    <ContentPage title={`${brandName} - Credit Authorization`}>
        <p>By submitting a request on this site, you acknowledge, agree, and authorize that:</p>
        <p>(a) CashForNoCredit.com, its providers, affiliates and respective agents may obtain consumer data and related information about you from one or more consumer reporting agencies via soft credit check to determine your eligibility for credit and identify products/services you may prequalify for;</p>
        <p>(b) your request and all information in it may be shown to multiple lenders, marketers, and lending partners in our lender network or in other lender networks (together "Lending Partners") in real time or over time so that one or more of them can decide whether to offer you a loan or to otherwise advertise their loan products to you;</p>
        <p>(c) you are providing written instruction under the Fair Credit Reporting Act and other applicable laws for our Lending Partners to obtain consumer reporting and other related information about you from one or more consumer reporting agencies (including, but not limited to, Experian) for the purposes of determining which Lending Partners (if any) to connect you with;</p>
        <p>(d) you are providing written instruction under the Fair Credit Reporting Act and other applicable laws for our Lending Partners, including, but not limited to the Lending Partners listed below, to obtain consumer reporting and other related information about you from one or more consumer reporting agencies (including, but not limited to TransUnion, Experian and Equifax) for the purposes of verifying your identity, determining whether you offer you a loan, determining how much debt you currently have in order to determine your debt-to-income ratio, and to make other prequalification or credit decisions;</p>
        <p>(e) your lender may disclose your loan information, including loan approval and funding status, to us;</p>
        <p>(f) many users of this site do not qualify for the loan types or amounts they request;</p>
        <p>(g) some lenders may only offer loans in an amount below the amount your requested;</p>
        <p>(h) any loan terms you may be offered are determined solely by your lender and not by us; and</p>
        <p>(i) you will closely review your loan offer(s) and research various loan options and alternatives available to you.</p>
    </ContentPage>
);

export default PersonalLoanNotice;
