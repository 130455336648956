import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReCAPTCHA from 'react-google-recaptcha';
import qs from 'qs';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import {
    ThemeProvider,
    Grid,
    Cell,
} from '@saladbob/sassafras';

import {
    Header, Footer, Nav, ScrollToTop,
} from './components';
import Lander from './views/Lander';
import About from './views/About';
import Faq from './views/Faq';
import Privacy from './views/Privacy';
import ResponsibleLending from './views/ResponsibleLending';
import Rates from './views/Rates';
import Ccpa from './views/Ccpa';
import Tc from './views/Tc';
import Econsent from './views/Econsent';
import MarketingDisclosure from './views/MarketingDisclosure';
import PersonalLoanNotice from './views/PersonalLoanNotice';
import CreditAuth from './views/CreditAuth';
import Unsub from './views/Unsub';
import Contact from './views/Contact';
import Providers from './views/ParticipatingProviders';
import Pop from './views/Pop';
import Inter from './views/Inter';
import TriviaView from './views/Trivia';
import EmailVerify from './views/EmailVerify';

import { ModalProvider } from './components/ModalProvider';

import './sass/app.scss';

const useQuery = (location) => new URLSearchParams(location.search);

const submitForm = async (url, data) => {
    const res = await fetch(`${url}?${qs.stringify(data, { encode: false })}`)
        .then((r) => r.text())
        .catch(() => '0: Error');
    return res;
};

const nav = [{
    key: 'home',
    link: '/',
    label: 'Home',
}, {
    key: 'blog',
    href: '/blog',
    label: 'Blog',
    target: '_self',
}, {
    key: 'about',
    link: '/about',
    label: 'About Us',
}, {
    key: 'faqs',
    link: '/faqs',
    label: 'FAQs',
}, {
    key: 'contact',
    link: '/contact',
    label: 'Contact Us',
}];

const RoutesElement = ({
    brandName,
    email,
    caEmail,
    address,
    publicPath,
    imagePath,
    brandPath,
    reCaptchaKey,
    footerNav,
    fieldsUrl,
    fieldsOrder,
    submitUrl,
    user,
}) => {
    const history = useNavigate();

    const location = useLocation();

    const [fields, setFields] = useState([]);

    const recaptchaRef = React.createRef();

    const [userData, setUserData] = useState(user);

    const [loading, setLoading] = useState(true);

    const query = useQuery(location);
    const qss = Number(query.get('step'));

    const [step, setStep] = useState(qss);
    // const [errMsg, setErrMsg] = useState();
    const [lastStep, setLastStep] = useState(0);

    const p = query.get('p');

    const onValidate = () => {
        setLoading(true);
    };

    const onError = () => {
        setLoading(false);
    };

    const onSubmit = async (_, data) => {
        setLoading(true);
        const newUserData = {
            ...userData,
            ...data,
        };

        if (data.submitForm) {
            const now = new Date();
            const ts = {
                tsd: `${now.getDate()}-${now.getMonth() + 1}-${now.getYear()}`,
                tst: `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
            };
            /* Always show the response
                const res = await submitForm(submitUrl, { ...newUserData, ...ts });
                if (res.includes('0:')) {
                    modal.open({
                        content: (<Type font="leader" tag="p" align="center">{res.split('0:')[1]}</Type>),
                    });
                } else {
                    setUserData(newUserData);
                    setLastStep(step);
                    history('/email-verify');
                }
            */
            await submitForm(submitUrl, { ...newUserData, ...ts });
            setUserData(newUserData);
            setLastStep(step);
            history('/email-verify');
        } else {
            setUserData(newUserData);
            setLoading(false);
            setLastStep(step);
            history(`/?step=${step + 1}`);
        }
        setLoading(false);
    };

    const prevStep = () => {
        setLastStep(step);
        const newStep = Math.max(0, step - 1);
        history(`/?step=${newStep}`);
    };

    const onSkip = () => {
        setLastStep(Math.max(3, lastStep));
        history('/?step=3');
    };

    useEffect(() => {
        const qstep = Number(query.get('step'));
        const em = query.get('em');
        if (em) {
            setUserData({ ...userData, em });
        }

        return setStep(qstep || 0);
    }, [location]);

    // Fetch Field Data
    useEffect(() => {
        const getData = async () => {
            const fieldData = await fetch(fieldsUrl)
                .then((res) => res.json())
                .then((res) => (res.length ? res : []))
                .catch(() => []);
            if (fieldsOrder.length > 0) {
                const fieldsObj = {};
                fieldData.forEach((field) => {
                    fieldsObj[field.key] = field;
                });
                setFields(fieldsOrder.reduce((acc, key) => {
                    if (fieldsObj[key]) {
                        acc.push(fieldsObj[key]);
                    }
                    return acc;
                }, []));
            } else {
                setFields(fieldData);
            }
            setLoading(false);
        };
        getData();
    }, []);

    return (
        <Grid
            columns="3fr minmax(0px, max-content)"
            rows="minmax(min-content, max-content) 1fr auto"
            area="'header header' 'main sidebar' 'footer footer'"
        >
            <Cell area="header">
                <Header
                    logo={`${publicPath}${imagePath}${brandPath}logo.svg`}
                    logoStep={`${publicPath}${imagePath}${brandPath}logo-icon.svg`}
                    nav={(<Nav nav={nav} color="neutral800" font="title" />)}
                    navMobile={(<Nav nav={nav} color="white" font="leader" vertical />)}
                    bgColor="white"
                    step={step}
                    steps={fields.length}
                />
            </Cell>
            <Cell area="main">
                <Routes>
                    <Route
                        path="/privacy"
                        element={(
                            <Privacy
                                brandName={brandName}
                                email={caEmail}
                                address={address}
                            />
                        )}
                    />
                    <Route
                        path="/terms"
                        element={(
                            <Tc
                                brandName={brandName}
                                email={email}
                                address={address}
                            />
                        )}
                    />
                    <Route
                        path="/ccpa"
                        element={(
                            <Ccpa
                                brandName={brandName}
                                reCaptchaKey={reCaptchaKey}
                                email={caEmail}
                                address={address}
                            />
                        )}
                    />
                    <Route path="/unsub" element={(<Unsub />)} />
                    <Route path="/contact" element={(<Contact reCaptchaKey={reCaptchaKey} email={email} />)} />
                    <Route path="/about" element={(<About />)} />
                    <Route path="/faqs" element={(<Faq brandName={brandName} />)} />
                    <Route path="/econsent" element={(<Econsent brandName={brandName} />)} />
                    <Route path="/plnotice" element={(<PersonalLoanNotice brandName={brandName} />)} />
                    <Route path="/disclosure" element={(<MarketingDisclosure brandName={brandName} />)} />
                    <Route path="/responsible" element={(<ResponsibleLending brandName={brandName} />)} />
                    <Route path="/creditauth" element={(<CreditAuth brandName={brandName} />)} />
                    <Route path="/rates" element={(<Rates brandName={brandName} />)} />
                    <Route path="/providers" element={(<Providers />)} />
                    <Route path="/trivia" element={(<TriviaView />)} />
                    <Route path="/pop" element={(<Pop />)} />
                    <Route path="/Inter" element={(<Inter />)} />
                    <Route
                        path="/email-verify"
                        element={(
                            <EmailVerify em={userData.em} />
                        )}
                    />
                    <Route
                        exact
                        path="/"
                        element={(
                            <>
                                <Lander
                                    brandName={brandName}
                                    fields={fields}
                                    onValidate={onValidate}
                                    onError={onError}
                                    onSubmit={onSubmit}
                                    prevStep={prevStep}
                                    onSkip={onSkip}
                                    imagePath={`${publicPath}${imagePath}${brandPath}`}
                                    step={step}
                                    lastStep={lastStep}
                                    userData={userData}
                                    loading={loading}
                                    publicPath={publicPath}
                                    p={p}
                                />
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={reCaptchaKey}
                                />
                            </>
                        )}
                    />
                </Routes>
            </Cell>
            <Cell area="footer">
                <Routes>
                    <Route
                        path="/email-verify"
                        element={(
                            <Footer
                                brandName={brandName}
                                disclaimer={false}
                                nav={(
                                    <Nav
                                        nav={footerNav}
                                        color="white"
                                        font={{ default: 'h5', laptop: 'control' }}
                                        weight="bold"
                                    />
                                )}
                            />
                        )}
                    />
                    <Route
                        path="*"
                        element={(
                            <Footer
                                brandName={brandName}
                                nav={(
                                    <Nav
                                        nav={footerNav}
                                        color="white"
                                        font={{ default: 'h5', laptop: 'control' }}
                                        weight="bold"
                                    />
                                )}
                            />
                        )}
                    />
                </Routes>
            </Cell>
        </Grid>
    );
};

const App = ({
    basename,
    publicPath,
}) => {
    const [loading, setLoading] = useState(true);
    const [theme, setTheme] = useState();

    // Fetch Theme Data
    useEffect(() => {
        const getData = async () => {
            const themeData = await fetch(`${publicPath}js/theme.json`).then((res) => res.json()).catch(() => {});
            setTheme(themeData);
            setLoading(false);
        };
        getData();
    }, []);

    return (
        <Router basename={basename}>
            <ScrollToTop />
            {theme && !loading && (
                <ThemeProvider theme={theme}>
                    <ModalProvider />
                    <RoutesElement />
                </ThemeProvider>
            )}
        </Router>
    );
};
App.defaultProps = {
    basename: window.basename || '/',
    publicPath: window.publicPath || 'public/',
};

RoutesElement.defaultProps = {
    brandName: window.brandName || 'AmazingJobs.com',
    email: window.brandEmail || 'demo@example.com',
    caEmail: window.caEmail || 'demo@example.com',
    address: window.address || {},
    publicPath: window.publicPath || 'public/',
    imagePath: window.imagePath || 'images/',
    brandPath: window.brandPath || '',
    reCaptchaKey: window.reCaptchaKey || '',
    footerNav: window.footerNav || [{}],
    fieldsUrl: window.fieldsUrl || '',
    fieldsOrder: window.fieldsOrder || [],
    submitUrl: window.submitUrl || '',
    user: window.user || {},
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
