/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */

import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import reactParse from 'html-react-parser';
import {
    P,
    Box,
    Type,
    FieldSelect,
    FieldText,
    FieldCheckbox,
    FieldDateCalendar,
    FieldDateSelect,
    FieldRadio,
    FieldAmount,
    Input,
    ThemeContext,
    Btn,
} from '@saladbob/sassafras';

import { ArrowRightCircle } from 'react-feather';

import PhoneField from './PhoneField';
import SsnField from './SsnField';
import AbaField from './AbaField';
import AddressField from './AddressField';
import Spinner from './Spinner';

const fieldMargin = [0, 0, 'md', 0];

function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date.getFullYear();
}

function addYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() + numOfYears);

    return date.getFullYear();
}

const dobYearStart = subtractYears(18);
const dobYearEnd = subtractYears(100);

const dateStart = addYears(0);
const dateEnd = addYears(10);

const OutputField = {
    text: (props) => (<FieldText {...props} />),
    hidden: (props) => (<Input type="hidden" {...props} />),
    number: (props) => (<FieldText type="tel" {...props} />),
    checkbox: ({
        text,
        size,
        label,
        ...props
    }) => (
        <FieldCheckbox spacing="xs" label={(<P>{reactParse(text)}</P>)} size="xs" {...props} />
    ),
    date: ({ noSubmit, ...props }, submit) => (
        <FieldDateCalendar
            minDate={new Date()}
            startYear={dateStart}
            endYear={dateEnd}
            onChange={noSubmit ? null : submit}
            {...props}
        />
    ),
    dob: (props) => (<FieldDateSelect startYear={dobYearStart} endYear={dobYearEnd} {...props} />),
    select: ({
        noSubmit,
        font,
        ...props
    }, submit) => (
        <FieldSelect
            onChange={noSubmit ? null : submit}
            font={font}
            {...props}
        />
    ),
    radio: ({
        noSubmit,
        ...props
    }, submit) => {
        const { colors } = useContext(ThemeContext);
        return (
            <FieldRadio
                onChange={noSubmit ? null : submit}
                checkedColor={colors.form.radio.checkedColor}
                checkedBgColor={colors.form.radio.checkedBgColor}
                uncheckedColor={colors.form.radio.uncheckedColor}
                uncheckedOutline={colors.form.radio.uncheckedOutline}
                uncheckedBgColor={colors.form.radio.uncheckedBgColor}
                {...props}
            />
        );
    },
    phone: (props) => (<PhoneField {...props} />),
    ssn: (props) => (<SsnField {...props} />),
    loanAmount: (props, submit) => {
        const { colors } = useContext(ThemeContext);
        return (
            <FieldAmount
                onChange={submit}
                margin={fieldMargin}
                align="center"
                justify="center"
                display="flex"
                checkedColor={colors.form.radio.checkedColor}
                checkedBgColor={colors.form.radio.checkedBgColor}
                uncheckedColor={colors.form.radio.uncheckedColor}
                uncheckedOutline={colors.form.radio.uncheckedOutline}
                uncheckedBgColor={colors.form.radio.uncheckedBgColor}
                {...props}
            />
        );
    },
    aba: (props) => (<AbaField {...props} />),
    address: ({
        userData, cityValue, stateValue, ...props
    }) => (
        <AddressField
            cityValue={userData.city || cityValue}
            stateValue={userData.state || stateValue}
            {...props}
        />
    ),
    submit: ({
        text,
        size = 'lg',
        font,
        disabled,
        hide,
        loading,
        icon,
        ...props
    }) => (hide ? null : (
        <Box justify="center" mb="md">
            {loading
                ? (
                    <Btn
                        size={size}
                        disabled={disabled}
                        font={font || 'h3'}
                        iconLeft={<Box width="24px"><Spinner size="sm" /></Box>}
                        iconRight={icon && <ArrowRightCircle size={32} />}
                        color="neutral"
                        type="button"
                        {...props}
                    >
                        {text || 'NEXT'}
                    </Btn>
                )
                : (
                    <Btn
                        size={size}
                        disabled={disabled}
                        font={font || 'h3'}
                        iconRight={icon && <ArrowRightCircle size={32} />}
                        {...props}
                    >
                        {text || 'NEXT'}
                    </Btn>
                )}
        </Box>
    )),
    desc: ({ text, ...props }) => (
        <P {...props}>
            {reactParse(text)}
        </P>
    ),
    disclosure: ({ text }) => (
        <P margin={['lg', 0]}>
            {reactParse(text)}
        </P>
    ),
};

const DynamicField = ({
    submit,
    type,
    font,
    userData,
    value,
    name,
    margin,
    size,
    label: fieldLabel,
    ...props
}) => {
    const sz = useMemo(() => {
        if (size === 'lg' || size === 'xl' || size === 'xxl') {
            return { phone: 'md', laptop: size };
        }
        return size;
    }, [size]);

    const fnt = useMemo(() => {
        if (font === 'h1' || font === 'h2' || font === 'h3') {
            return { phone: 'h4', laptop: font };
        }
        return font || 'h4';
    }, [font]);

    const mg = useMemo(() => {
        if (margin) {
            return { phone: fieldMargin, laptop: margin };
        }
        return fieldMargin;
    }, [margin]);

    const label = useMemo(() => {
        const { align } = props;
        if (fieldLabel) {
            return (
                <Type font={fnt} display="block" align={align || 'center'} mb="0">
                    {fieldLabel}
                </Type>
            );
        }
        return null;
    }, [fieldLabel, fnt, props.align]);

    return (typeof OutputField[type] === 'function' ? OutputField[type]({
        font: fnt,
        margin: mg,
        value: userData[name] || value,
        size: sz,
        userData,
        name,
        label,
        text: fieldLabel,
        ...props,
    }, submit) : '');
};

DynamicField.propTypes = {
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    userData: PropTypes.object,
    name: PropTypes.string,
    rules: PropTypes.object,
    label: PropTypes.string,
    submit: PropTypes.func,
    value: PropTypes.string,
    desc: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.bool,
};

export default DynamicField;
