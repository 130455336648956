/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities  */

import React from 'react';

import ContentPage from '../components/ContentPage';

const MarketingDisclosure = ({ brandName }) => (
    <ContentPage title="Marketing Disclosure">
        <p>
            {brandName}
            {' '}
            (the "Website") is not a lender and does not broker loans to lenders and does not make cash advances or credit decisions, and its only financial connection to the providers of the goods and services referred to on the Website is as a marketing vendor. Accessing Website also does not constitute an offer or a solicitation to lend. As such, Website may receive compensation, in the form of a payment for each qualified sale or for each customer registration form Website submits. Website may also be advertising on behalf of a third-party advertiser with whom the provider of the products or services also has a financial connection with, in that both advertisers may receive compensation for each qualified sale or payment for each customer referral. Therefore, the amount of compensation provided, along with other factors, may impact the entity to whom Website provides your registration form. Upon submitting a registration form, you may be redirected to the third-party website or contacted by the third-party for the product you have requested. We encourage you to review the third-party privacy practices and terms and conditions, which may differ substantially from Website. In the event you are provided a loan package, you should review and understand the terms of the product before agreeing to the same. Website and its owners are not affiliated with these third-parties other than what has been described above. Website does not recommend or endorse any specific lender, product, or service on Website, and Website does not include all companies or all available offers. You are encouraged to research all available loan and financial products and service offers for your situation.
        </p>
    </ContentPage>
);

export default MarketingDisclosure;
