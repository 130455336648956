import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
    ThemeContext, Box, P, Type, BtnText,
} from '@saladbob/sassafras';

import Modal from './Modal';

const styles = createUseStyles({
    Footer: {
        width: '100%',
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
});

const Footer = ({
    brandName,
    disclaimer = true,
    nav, ...props
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, ...props });

    const [md, updateMd] = useState(false);

    const showMd = (e) => {
        e.preventDefault();
        updateMd(true);
    };

    const closeMd = () => {
        updateMd(false);
    };

    return (
        <>
            <div className={classes.Footer}>
                <Box bgColor="primary800" align="center">
                    {disclaimer && (
                        <Box txtColor="white" maxWidth="1200px" padding="md" margin="auto">
                            <P>
                                <Type tag="strong" weight="bold">Consumer Notice:</Type>
                                {' '}
                                A personal loan is an online loan ranging generally from $500 to $40,000 that can
                                be used to fund a large expense. These personal loans are intended for short-term
                                financial relief
                                and do not constitute long-term financial solutions. Consumers facing debt and credit
                                difficulties should seek out debt and credit advisory help. You will not be charged any
                                fees to use our services. Submitting a personal loan request on this site does not
                                guarantee that you will be connected with independent lenders or approved for a personal
                                loan. Our lender network includes state and Tribal lenders. Tribal lenders’ rates and
                                fees may be higher than state-licensed lenders, and are subject to federal and tribal
                                laws, not state laws. In addition to traditional loans, some lenders in our network
                                may offer line of credit.
                            </P>
                            <P>
                                <Type tag="strong" weight="bold">
                                    Disclaimer:
                                    {' '}
                                    {brandName}
                                    &apos;s
                                </Type>
                                {' '}
                                Terms of Use and Privacy Policy apply to the use of this website and the services it
                                provides. This site is not a lender, does not broker loans to lenders, and does not make
                                cash advances or credit decisions. This site also does not constitute an offer or a
                                solicitation to lend. This site offers an infomediary service that will attempt to
                                connect you with independent, third-party lenders and financial service providers who
                                may secure a personal loan for you and thus the specific terms and conditions of the
                                specific lender will apply to any loan a borrower takes out. Any display of APR, loan
                                amounts, interest or other loan details are only estimations. In reality these amounts
                                will vary by lender and by borrower. Lending decisions are based on many factors,
                                including but not limited to credit history, employment history, and loan repayment
                                history. Please note that some lenders may perform credit checks as part of their
                                credit transaction approval process which may or may not affect your credit score.
                                Credit checks through TransUnion, Equifax, or Experian may leave a record and affect
                                your credit score.  By submitting information through our website for the Lenders,
                                you affirmatively agree to allow such Lenders to review, verify, and research your
                                information in this manner. For information and questions regarding personal loans
                                and credit checks, please contact your lender directly. You are under no obligation
                                to use our service to initiate contact, or request for credit or
                                any loan product with any service provider or lender. The operator of this website
                                is a marketer who is compensated for their services as described
                                in our
                                {' '}
                                <BtnText href="#" onClick={showMd} txtColor="primary200">marketing disclosure</BtnText>
                                {' '}
                                and does not
                                endorse or recommend any lender on or through this site.
                            </P>
                            <P>
                                <Type tag="strong" weight="bold">Limitations:</Type>
                                {' '}
                                Residents of some states, including but
                                not limited to Arkansas, New York, and West Virginia, may not qualify for short-term
                                loans provided by the lenders and third-parties they are connected with on this
                                website. Loan terms, including loan amounts, interest rates, and transfer times for
                                loans vary among lenders and may be dependent upon the laws of the state in which you
                                reside. Our website makes no warranties, guarantees, or representations that you will
                                qualify for any third-party services by using our website or completing an inquiry
                                form. The services provided on this website are void where prohibited. Our website
                                is not intended for use by citizens of the European Union and we specifically
                                exclude those citizens, wherever located.
                            </P>
                        </Box>
                    )}
                </Box>
                <Box bgColor="primary700" padding="md">
                    {nav}
                </Box>
            </div>
            <Modal show={md} onClose={closeMd} bgColor="white" txtColor="black" btnColor="primary">
                <Box padding="md">
                    <P>
                        The owner of this website is not a lender or broker, its only financial connection to the
                        providers of the goods and services referred to on the website is a marketing vendor. As such,
                        we may receive compensation, in the form of a payment for each qualified sale or for each
                        customer registration form we submit. The owner may also be advertising on behalf of a
                        third-party advertiser with whom the provider of the products or services also has a financial
                        connection with, in that both advertisers may receive compensation for each qualified sale or
                        payment foreach customer referral. Therefore, the amount of compensation provided, along
                        with other factors,may impact the entity to whom we provide your registration form. Upon
                        submitting a registration form, you may be redirected to the third-party website or contacted
                        by the third-party for the product you have requested. We encourage you to review the
                        third-party privacy practices and terms and conditions, which may differ substantially from
                        this website. In the event you areprovided a loan package, you should review and understand
                        the terms of the product before agreeing to the same. This website and its owners are not
                        affiliated with these third-party other than what has been described above. The owner of this
                        website does not recommend or endorse any specific lender, product, or service on this website
                        and this website does not include all companies or all available offers. You are encouraged to
                        research all available loan and financial products and services offers for your situation.
                    </P>
                </Box>
            </Modal>
        </>
    );
};

Footer.propTypes = {
    brandName: PropTypes.string,
    logo: PropTypes.string,
    bgColor: PropTypes.string,
    jobOptions: PropTypes.object,
    search: PropTypes.bool,
    nav: PropTypes.node,
};

Footer.defaultProps = {};

export default Footer;
