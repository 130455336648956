/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities  */

import React from 'react';

import ContentPage from '../components/ContentPage';

const PersonalLoanNotice = ({ BrandName }) => (
    <ContentPage title="Personal Loan Notice">
        <p>
            {BrandName}
            {' '}
            is customized towards personal loan products. However, we also work with lenders and third-party financial service providers who offer other short-term loans and or financial services in an effort to assist consumers with varying types of creditworthiness. Upon completing your registration form, we will send the same out to all of our financial service providers. Typically, the majority of consumers connected with lenders who offer loans are only presented with a short-term lending options between $100 - $1,000. These loans may have less favorable rates and shorter terms than personal loans. We encourage you to research the best loan option for your situation. You agree to lookup whether you have an existing account with CashForNoCredit.com or its affiliated websites and utilize your existing account information for this request.
        </p>
    </ContentPage>
);

export default PersonalLoanNotice;
