/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities  */

import React from 'react';

import ContentPage from '../components/ContentPage';

const PersonalLoanNotice = () => (
    <ContentPage title="E-Consent Notice">
        <p>Please read this information carefully and print a copy and/or retain this information for future reference. </p>
        <h3>Introduction</h3>
        <p>By submitting a request with us, you are agreeing to be connected with one of our third-party lenders either through our direct network of lenders or through our extended network of lenders. These third-party lenders require your consent to use and accept electronic signatures, records, and disclosures ("E-Consent"). After you provide the E-Consent, we may show you additional ads or offers for lenders and/or other credit-related products or services like debt relief, credit repair, credit monitoring. You will also be added to our marketing list and receive additional marketing communications from lenders or other credit-related providers. This E-Consent notice informs you of your rights when receiving electronic disclosures, notices, and other information. By clicking on the link assenting to our Terms and Conditions and Privacy Policy, you acknowledge that you received this E-Consent and that you consent to conduct transactions using electronic signatures, electronic disclosures, electronic records, and electronic contract documents ("Disclosures").</p>
        <h3>Option for Paper or Non-Electronic Records</h3>
        <p>You may contact the third-party lender directly to request paper copy of any Disclosures at no charge to you. The lenders will retain all Disclosures in accordance with applicable law.</p>
        <h3>Scope of Consent</h3>
        <p>This E-Consent covers all online and electronic interactions (including those on a mobile device) between you and the third-party lender, ads or offers for lenders and/or other credit-related products or services like debt relief, credit repair, credit monitoring, including phones, smart-phones, and tablets. The third-party lender can utilize this E-Consent to process your information, transact with you electronically, and send you related electronic notices.</p>
        <h3>Consenting to Do Business Electronically</h3>
        <p>Please confirm that you have the required hardware and software listed below before deciding to conduct business electronically with any third party lender. If you have any questions regarding these requirements, please contact the lender directly.</p>
        <p>• A device capable of accessing the Internet</p>
        <p>• An e-mail account</p>
        <p>• An Internet Browser software program that supports at least 128 bit encryption, i.e. Microsoft® Internet Explorer or Mozilla Firefox®</p>
        <p>• To read some documents, you may need a PDF file reader </p>
        <p>• A printer or a long-term storage device to retain a copy of the Disclosures for future reference. </p>
        <h3>Withdrawing Consent</h3>
        <p>The E-Consent you provide to us for our third-party lenders’ evaluation of the information you provide, cannot be withdrawn as it is a one-time transaction. However, if you are connected with one or more third-party lenders, you are free to withdraw your E-Consent with those third-party lenders at any time and at no charge by contacting the third-party lender directly. Please keep in mind that withdrawing this E-Consent before receiving credit, you may be prevented from obtaining credit from the lender. The validity and enforceability of prior electronic Disclosures will not be affected by your withdrawal of this E-Consent.</p>
        <h3>Updating Your Contact Information</h3>
        <p>You should contact third-party lenders to keep them informed of any change in your electronic address or mailing address. </p>
        <h3>YOUR ABILITY TO ACCESS DISCLOSURES</h3>
        <p>BY CLICKING THE LINK, YOU AGREE TO OUR TERMS AND CONDITIONS AND PRIVACY POLICY. YOU ACKNOWLEDGE THAT YOU CAN ACCESS THE DISCLOSURES USING THE METHODS DESCRIBED ABOVE.</p>
        <h3>CONSENT</h3>
        <p>BY CLICKING THE LINK, YOU AGREE TO THE TERMS AND CONDITIONS AND PRIVACY POLICY. YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ THE INFORMATION CONTAINED IN THIS E-CONSENT NOTICE AND YOU CONSENT TO USING ELECTRONIC SIGNATURES, HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM AND TO DOING BUSINESS WITH THE THIRD-PARTY LENDER ELECTRONICALLY. YOU ACKNOWLEDGE AND AGREE THAT YOU MAY REQUEST A PAPER COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WILL BE PROVIDED TO YOU AT NO CHARGE. IF YOU REFRAIN FROM PROCEEDING THEN YOU HAVE DECIDED TO NEITHER WISH TO USE ELECTRONIC SIGNATURES NOR CONDUCT THIS TRANSACTION ELECTRONICALLY. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR CONSENT TO ELECTRONIC DISCLOSURES IS REQUIRED TO RECEIVE SERVICES FROM THIRD PARTY LENDERS OVER THE INTERNET.</p>
    </ContentPage>
);

export default PersonalLoanNotice;
