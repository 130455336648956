import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, Lock } from 'react-feather';

import reactParse from 'html-react-parser';

import {
    Box,
    Btn,
    Grid,
    Container,
    H1,
    H2,
    H3,
    H4,
    P,
    ThemeContext,
    ValidationForm,
} from '@saladbob/sassafras';

import Lander2ColBg from './StepTypes/Lander2ColBg';
import DynamicField from './DynamicField';
import HelpBox from './HelpBox';

const icons = {
    lock: (<Lock />),
};

const FormStep = (props) => {
    const {
        title,
        titleSize,
        subtitle,
        subtitleSize,
        formTitle,
        formTitleSize,
        formSubtitle,
        formSubtitleSize,
        instructions,
        instructionsIcon,
        helpBox,
        image,
        optin,
        bottomText,
        style,
        bgImage,
        currentStep,
        lastStep,
        fields,
        onSubmit,
        prevStep,
        disabled,
        userData,
        imgPath,
        publicPath,
        loading,
    } = props;

    const formRef = useRef();

    const submitForm = (e) => {
        e.preventDefault();
        formRef.current.validate();
    };

    const { colors } = useContext(ThemeContext);

    switch (style) {
        case 'lander':
            return (
                <Box
                    maxWidth="100%"
                    margin="auto"
                    minHeight="50vh"
                    padding={{ default: ['md'], laptop: ['xxl', 'md', 0, 'md'] }}
                >
                    <Box maxWidth="640px" margin="auto">
                        {title && (
                            <H1 font={{ default: 'h2', laptop: 'jumbo' }} align="center" mb="0">
                                {reactParse(title)}
                            </H1>
                        )}
                        {subtitle && (
                            <H1
                                font={{ default: 'h1', laptop: 'jumbo2' }}
                                fw="bold"
                                color="primary"
                                align="center"
                                mb="lg"
                            >
                                {reactParse(subtitle)}
                            </H1>
                        )}
                        <Grid columns={{ default: '1fr', laptop: '5fr 3fr' }} columnGap="md" rowGap="md">
                            <ValidationForm ref={formRef} disabled={disabled} onSubmit={onSubmit}>
                                {fields
                                && fields.map(({ hide, ...field }) => (
                                    <DynamicField
                                        key={field.name}
                                        submit={submitForm}
                                        userData={userData}
                                        hide={hide && lastStep <= currentStep}
                                        loading={loading}
                                        {...field}
                                    />
                                ))}
                            </ValidationForm>
                            <Box>
                                <Btn
                                    size="lg"
                                    width="100%"
                                    onClick={submitForm}
                                    disabled={disabled}
                                    font="h3"
                                >
                                    GET STARTED
                                </Btn>
                            </Box>
                        </Grid>
                        {currentStep > 0 && (
                            <Box justify="center" mb="lg">
                                <Btn onClick={prevStep} color="none" txtColor={colors.form.txtColor}>
                                    <ChevronLeft />
                                    {' '}
                                    Back
                                </Btn>
                            </Box>
                        )}
                        {optin && (
                            <P margin={['lg', 0]}>
                                {reactParse(optin)}
                            </P>
                        )}
                        {bottomText && (
                            <P>
                                {reactParse(bottomText)}
                            </P>
                        )}
                    </Box>
                    <Box
                        bgImage={`${publicPath}${imgPath}${bgImage}`}
                        bgSize="contain"
                        bgPos="center bottom"
                        width="100%"
                        minHeight="400px"
                        margin={['lg', 0, 0, 0]}
                    />
                </Box>
            );
        case 'lander2col':
            return (
                <Box padding={[0, 'md']}>
                    <Container
                        margin="auto"
                        size="1120px"
                        padding={['xl', 0, 0, 0]}
                        minHeight="100%"
                        verticalAlign="center"
                    >
                        <Grid
                            width="100%"
                            gridGap="md"
                            columns={{ default: '1fr', laptop: '5fr 3fr' }}
                        >
                            <Box verticalAlign="center">
                                {title && (
                                    <H1
                                        align="center"
                                        font={{ phone: 'h2', laptop: titleSize || 'jumbo2' }}
                                        mb={{ phone: '0', laptop: 'md' }}
                                    >
                                        {reactParse(title)}
                                    </H1>
                                )}
                                {subtitle && (
                                    <H2
                                        align="center"
                                        font={{ default: 'h1', laptop: subtitleSize || 'jumbo2' }}
                                        fw="bold"
                                        color="primary"
                                        mb={{ phone: '0', laptop: 'lg' }}
                                    >
                                        {reactParse(subtitle)}
                                    </H2>
                                )}
                                {image && (
                                    <Box
                                        display={{ default: 'none', laptop: 'block' }}
                                        bgImage={`${publicPath}${imgPath}${image}`}
                                        bgSize="100% auto"
                                        bgPos="left top"
                                        width="100%"
                                        minHeight="600px"
                                        height="100%"
                                    />
                                )}
                            </Box>
                            <Box>
                                <Box
                                    bgColor="white"
                                    padding="md"
                                    depth="lg"
                                    bdColor="secondary"
                                    bdWidth={[0, 0, 0, '1rem']}
                                    rounded="lg"
                                >
                                    {formTitle && (
                                        <H3
                                            font={{ default: 'h3', laptop: formTitleSize || 'h1' }}
                                            mb={formSubtitle ? 0 : 'md'}
                                            align="center"
                                        >
                                            {reactParse(formTitle)}
                                        </H3>
                                    )}
                                    {formSubtitle && (
                                        <H4
                                            font={{ default: 'p', laptop: formSubtitleSize || 'h3' }}
                                            mb="md"
                                            align="center"
                                        >
                                            {reactParse(formSubtitle)}
                                        </H4>
                                    )}
                                    <ValidationForm ref={formRef} disabled={disabled} onSubmit={onSubmit}>
                                        {fields
                                        && fields.map(({ hide, ...field }) => (
                                            <DynamicField
                                                key={field.name}
                                                submit={submitForm}
                                                userData={userData}
                                                hide={hide && lastStep <= currentStep}
                                                loading={loading}
                                                {...field}
                                            />
                                        ))}
                                    </ValidationForm>
                                    {optin && (
                                        <P margin={['lg', 0]}>
                                            {reactParse(optin)}
                                        </P>
                                    )}
                                    {bottomText && (
                                        <P>
                                            {reactParse(bottomText)}
                                        </P>
                                    )}
                                    <Box width="260px" margin={['lg', 'auto']}>
                                        <img
                                            src={`${publicPath}${imgPath}ssl.svg`}
                                            style={{ width: '100%', display: 'bloxk' }}
                                            alt="WE USE 256-BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Container>
                </Box>
            );
        case 'lander2colbg':
            return (<Lander2ColBg {...props} />);
        case 'page':
            return (
                <Box
                    width="100%"
                    margin="auto"
                    minHeight="calc(100vh - 78px)"
                    bgImage={bgImage}
                    bgSize="cover"
                    padding={{ default: ['md', 0], laptop: ['xxl', 0, 'lg', 0] }}
                >
                    <Box maxWidth="660px" margin="auto" padding={[0, 'md']}>
                        {title && (
                            <H1 font={{ default: 'h4', laptop: 'h1' }} align="center" mb={subtitle ? '0' : 'md'}>
                                {reactParse(title)}
                            </H1>
                        )}
                        {subtitle && (
                            <H1 font={{ default: 'h5', laptop: 'h3' }} fw="bold" color="primary" align="center" mb="lg">
                                {reactParse(subtitle)}
                            </H1>
                        )}
                        <Grid columns="1fr" columnGap="md" rowGap="md">
                            <ValidationForm ref={formRef} disabled={disabled} onSubmit={onSubmit}>
                                {fields
                                && fields.map(({ hide, ...field }) => (
                                    <DynamicField
                                        key={field.name}
                                        submit={submitForm}
                                        userData={userData}
                                        hide={hide && lastStep <= currentStep}
                                        loading={loading}
                                        {...field}
                                    />
                                ))}
                            </ValidationForm>
                        </Grid>
                        {currentStep > 0 && (
                            <Box justify="center" mb="lg">
                                <Btn onClick={prevStep} color="none" txtColor={colors.form.txtColor}>
                                    <ChevronLeft />
                                    {' '}
                                    Back
                                </Btn>
                            </Box>
                        )}
                        {optin && (
                            <P margin={['lg', 0]}>
                                {reactParse(optin)}
                            </P>
                        )}
                        {bottomText && (
                            <P>
                                {reactParse(bottomText)}
                            </P>
                        )}
                    </Box>
                    <Box width="220px" margin={['lg', 'auto']}>
                        <img
                            src={`${publicPath}${imgPath}ssl-v2.svg`}
                            style={{ width: '100%', display: 'bloxk' }}
                            alt="WE USE 256-BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA"
                        />
                    </Box>
                </Box>
            );
        default:
            return (
                <Box
                    maxWidth="660px"
                    minHeight="calc(100vh - 88px)"
                    margin="auto"
                    padding={{ default: 'md', laptop: ['xxl', 0] }}
                >
                    <H2 font={{ default: 'h4', laptop: 'h1' }} align="center" mb="sm">
                        {reactParse(title)}
                    </H2>
                    {subtitle && (
                        <P weight="500" align="center" mb="md" font="h5">
                            {reactParse(subtitle)}
                        </P>
                    )}
                    {instructions && (
                        <Box bgColor="neutral75" padding="sm" margin={[0, 0, 'md', 0]} rounded="xs" justify="center">
                            {instructionsIcon && icons[instructionsIcon]}
                            <P weight="500" align="center" mb="0" font={instructionsIcon ? 'p' : 'h5'}>
                                {reactParse(instructions)}
                            </P>
                        </Box>
                    )}
                    {helpBox && (<HelpBox {...helpBox} />)}
                    {image && (
                        <Box margin={[0, 'auto', 'md', 'auto']} maxWidth="420px">
                            <img
                                src={`${publicPath}${imgPath}${image}`}
                                alt={title}
                                style={{ maxWidth: '100%', display: 'block' }}
                            />
                        </Box>
                    )}
                    <ValidationForm ref={formRef} disabled={disabled} onSubmit={onSubmit}>
                        {fields && fields.map(({ hide, ...field }) => (
                            <DynamicField
                                key={field.name}
                                userData={userData}
                                submit={submitForm}
                                disabled={disabled}
                                hide={hide && lastStep <= currentStep}
                                loading={loading}
                                {...field}
                            />
                        ))}
                    </ValidationForm>
                    {currentStep > 0 && (
                        <Box justify="center" mb="lg">
                            <Btn onClick={prevStep} color="none" txtColor={colors.form.txtColor}>
                                <ChevronLeft />
                                {' '}
                                Back
                            </Btn>
                        </Box>
                    )}
                    {bottomText && (
                        <P>
                            {reactParse(bottomText)}
                        </P>
                    )}
                    <Box maxWidth="260px" margin={['lg', 'auto']}>
                        <img
                            src={`${publicPath}${imgPath}ssl.svg`}
                            style={{ width: '100%', display: 'bloxk' }}
                            alt="WE USE 256-BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA"
                        />
                    </Box>
                    <Box margin={['lg', 'auto']}>
                        <P font="control" align="center" padding={[0, 0, 'sm', 0]}>
                            <a href="/blog/privacy/">
                                Ad disclosure
                            </a>
                            {' | '}
                            <a href="/blog/privacy/">
                                Privacy Policy
                            </a>
                            {' | '}
                            <a href="/blog/terms/">
                                Terms & Conditions
                            </a>
                        </P>
                    </Box>
                </Box>
            );
    }
};

FormStep.propTypes = {
    title: PropTypes.string,
    titleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    subtitle: PropTypes.string,
    subtitleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    formTitle: PropTypes.string,
    formTitleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    formSubtitle: PropTypes.string,
    formSubtitleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    instructions: PropTypes.string,
    instructionsIcon: PropTypes.string,
    publicPath: PropTypes.string,
    imgPath: PropTypes.string,
    helpBox: PropTypes.object,
    image: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.object),
    optin: PropTypes.object,
    bottomText: PropTypes.string,
    style: PropTypes.string,
    bgImage: PropTypes.string,
    onSubmit: PropTypes.func,
    prevStep: PropTypes.func,
    currentStep: PropTypes.number,
    lastStep: PropTypes.number,
    stepCount: PropTypes.number,
    disabled: PropTypes.bool,
    userData: PropTypes.object,
    loading: PropTypes.bool,
};

FormStep.defaultProps = {
    publicPath: window.publicPath || 'public/',
    imgPath: window.imgPath || 'images/',
};

export default FormStep;
