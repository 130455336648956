import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from 'react-feather';
import {
    Box, Container, P, H2, H3, H4, H5, Grid, ThemeContext,
} from '@saladbob/sassafras';

import BlurbForm from './BlurbForm';
import BlurbSearch from './BlurbSearch';
import BlurbMatch from './BlurbMatch';

const LanderContent = ({
    publicPath = window ? window.publicPath : '/public/',
    imgPath = window ? window.imgPath : '/images/',
}) => {
    const theme = useContext(ThemeContext);
    return (
        <>
            <Box>
                <Container size="lg" margin="auto" padding={['xxl', 'md']}>
                    <H2
                        align="center"
                        mb={{ phone: 'lg', laptop: 'xl' }}
                        font={{ phone: 'h2', laptop: 'large' }}
                    >
                        How it works
                    </H2>
                    <Grid columnGap="xxl" columns={{ default: '1fr', laptop: '1fr 1fr 1fr' }} margin="auto">
                        <Box>
                            <BlurbForm />
                            <H5 weight="bold">Share some info with us!</H5>
                            <P>
                                Tell us a little about yourself so we can match you with the best loan—don’t
                                worry, it won’t take too long.
                            </P>
                        </Box>
                        <Box>
                            <BlurbSearch />
                            <H5 weight="bold">We’ll process the data for you</H5>
                            <P>
                                Our sophisticated platform sorts through things like interest rates and loan
                                terms to find
                                potential loans that you’d pair best with.
                            </P>
                        </Box>
                        <Box>
                            <BlurbMatch />
                            <H5 weight="bold">Get set up with an ideal lender</H5>
                            <P>
                                Once we’ve sifted through the options, we can help link you directly with a
                                loan provider,
                                or offer you options so you can reach out directly to the lender you’re interested in.
                            </P>
                        </Box>
                    </Grid>
                </Container>
            </Box>
            <Box
                bgColor="primary700"
                txtColor="white"
            >
                <Grid columnGap="lg" columns={{ default: '1fr', laptop: '1fr 1fr' }}>
                    <Box
                        bgImage={theme.config.home.howItWorks.image || `${publicPath}${imgPath}how-it-works.jpg`}
                        bgPos="right top"
                    />
                    <Box maxWidth="520px" padding={['xxl', 'md']}>
                        <H3>We want to make the loan process easier!</H3>
                        <H3 color="primary200" mb="lg">Let’s cut through all the hassle, together</H3>

                        <H4 icon={(<CheckCircle />)}>Quick turnaround</H4>
                        <P mb="lg" color="neutral500">
                            We want you to find what you’re looking for after just a few moments, and once
                            approved, you can even get the money you need into your personal bank account in as soon
                            as just one day.
                        </P>
                        <H4 icon={(<CheckCircle />)}>Terms that work for you</H4>
                        <P mb="lg" color="neutral500">
                            Need flexibility? We can help get you set up with whatever terms you need, including
                            but not limited to lower monthly payments, or a payment plan
                        </P>
                        <H4 icon={(<CheckCircle />)}>Resources you can trust</H4>
                        <P mb="lg" color="neutral500">
                            We only recommend accredited lenders that we have thoroughly vetted in advance, so
                            you get service you can put your faith in
                        </P>
                    </Box>
                </Grid>
            </Box>
            <Box bgColor="neutral50">
                <Container size="lg" margin="auto" padding={['xxl', 'md']}>
                    <H2 align="center" mb="xxl" font="large">But why should you work with us?</H2>
                    <Grid columnGap="lg" columns={{ default: '1fr', laptop: '1fr 1fr 1fr 1fr' }} margin="auto">
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src={`${publicPath}${imgPath}icons/icon-1.svg`}
                                    alt="A network of professionals"
                                    style={{ maxWidth: '96px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H3 mb="md" align="center">A network of professionals</H3>
                            <P align="center">
                                We work with a team of lenders that are trustworthy, experienced, and actively looking
                                for new clients to service.
                            </P>
                        </Box>
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src={`${publicPath}${imgPath}icons/icon-2.svg`}
                                    alt="Quick results"
                                    style={{ maxWidth: '96px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H3 mb="md" align="center">Quick results</H3>
                            <P align="center">
                                We know that time is of the essence, and money can be in your account incredibly fast,
                                often as soon as one business day later.
                            </P>
                        </Box>
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src={`${publicPath}${imgPath}icons/icon-3.svg`}
                                    alt="Full protection"
                                    style={{ maxWidth: '96px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H3 mb="md" align="center">Full protection</H3>
                            <P align="center">
                                We’re committed to keeping your personal information safe—we use a certified encryption
                                system to ensure your data is protected.
                            </P>
                        </Box>
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src={`${publicPath}${imgPath}icons/icon-4.svg`}
                                    alt="All are welcome"
                                    style={{ maxWidth: '96px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H3 mb="md" align="center">All are welcome</H3>
                            <P align="center">
                                We understand not everyone has perfect credit, and we encourage all candidates to apply,
                                even if your credit history has some issues. We’re here to help!
                            </P>
                        </Box>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <Container size="lg" margin="auto" padding={['xxl', 'md']}>
                    <H2 align="center" mb="xxl" font="large">FAQs</H2>
                    <Grid columnGap="lg" rowGap="lg" columns={{ default: '1fr', laptop: '1fr 1fr 1fr' }} margin="auto">
                        <Box>
                            <H3 mb="md">What is a personal loan?</H3>
                            <P>
                                Personal loans are used for a wide variety of reasons, like home renovations or
                                paying off debt, even for travel. Much of the personal loan application process is
                                incredibly convenient—forms and documents can be completed entirely online in many
                                cases. It’s a flexible option for those who don’t want to take on credit card debt,
                                and these loans are usually paid back via a monthly or weekly payment plan.
                            </P>
                        </Box>
                        <Box>
                            <H3 mb="md">What do I have to pay?</H3>
                            <P>
                                It depends. Part of the repayment will include not just the money you borrowed,
                                but also APRs, annual percentage rates. The APR will vary depending on where you
                                live, your credit history, and the lender you use. Terms and work history also
                                play a part in the final number. But all of this will be discussed when you start
                                talks with your potential loan provider, you will never be left in the dark. By
                                starting the loan process now, you’re on the road to getting these questions
                                answered by your provider!
                            </P>
                        </Box>
                        <Box>
                            <H3 mb="md">How quickly can I get my loan?</H3>
                            <P>
                                A credit check is a usual first step for many loan providers. Your credit history
                                will be run through certified, national platforms like DataX, DP Bureau, or Teletrack,
                                or through one of the three main credit reporting agencies: Equifax, TransUnion, or
                                Experian. It’s nothing to worry about, this is completely normal, but the time it
                                takes to complete this check will be different from lender to lender.
                            </P>
                        </Box>

                        <Box>
                            <H3 mb="md">When will I have the money?</H3>
                            <P>
                                The answer to this question depends on a few things. Different providers have different
                                turnaround times, and it can also come down to how quickly your own bank processes
                                transactions. But the good news is, many applicants can expect the funds to be
                                approved and deposited into their account in as little as one business day.
                            </P>
                        </Box>
                        <Box>
                            <H3 mb="md">How soon do I have to pay it back?</H3>
                            <P>
                                This again is a variable. The repayment plan is something you will work out with
                                the lender, and payment times vary between providers. It is important that you
                                understand the parameters before agreeing to a loan contract—always read terms and
                                conditions carefully before signing onto anything.
                            </P>
                        </Box>
                        <Box>
                            <H3 mb="md">If I can’t pay, what happens next?</H3>
                            <P>
                                You may have to pay a new finance charge if you are unable to cover the principal
                                and interest on the appropriate date. There is a chance the payment can be moved
                                to a new date, but such a course must be discussed with the loan provider. We are
                                not the enforcers of the payments, your lender is, and they could potentially
                                take legal action if you fall back on your agreement.
                            </P>
                        </Box>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <Container size="lg" margin="auto" padding={['xxl', 'md']}>
                    <H2 align="center" mb="xxl" font="large">Just 3 easy steps to secure a loan!</H2>
                    <Grid columnGap="lg" columns={{ default: '1fr', laptop: '1fr 1fr 1fr' }} margin="auto">
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src={`${publicPath}${imgPath}icons/icon-5.svg`}
                                    alt="Complete our short online form, should take just a few minutes"
                                    style={{ maxWidth: '160px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H5 align="center">Complete our short online form, should take just a few minutes</H5>
                        </Box>
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src={`${publicPath}${imgPath}icons/icon-6.svg`}
                                    alt="Read over and sign the loan paperwork once approved"
                                    style={{ maxWidth: '160px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H5 align="center">Read over and sign the loan paperwork once approved</H5>
                        </Box>
                        <Box>
                            <Box align="center" justify="center" padding="md">
                                <img
                                    src={`${publicPath}${imgPath}icons/icon-7.svg`}
                                    alt="In as little as a day, the money is in your account!"
                                    style={{ maxWidth: '160px', width: '100%', height: 'auto' }}
                                />
                            </Box>
                            <H5 align="center">In as little as a day, the money is in your account!</H5>
                        </Box>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

LanderContent.propTypes = {
    publicPath: PropTypes.string,
    imgPath: PropTypes.string,
};

export default LanderContent;
