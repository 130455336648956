import React, {
    useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Btn,
    H3,
    Type,
    ThemeContext,
} from '@saladbob/sassafras';

import styles from './Joke.styles.css';

import Badge from '../svg/Badge';

const transformNone = {
    transform: 'none',
};

const Joke = ({
    question,
    correctAnswer,
    onNext,
    slideTime,
}) => {
    const { colors } = useContext(ThemeContext);
    const closedContainer = useRef();
    const openContainer = useRef();
    const [answer, setAnswer] = useState();
    const [height, setHeight] = useState();

    useEffect(() => {
        if (typeof answer !== 'undefined') {
            setTimeout(() => { onNext(true); }, slideTime || 1800);
        }
        setHeight(answer ? openContainer.current.scrollHeight : closedContainer.current.scrollHeight);
    }, [answer]);

    return (
        <Box
            maxWidth="400px"
            margin="auto"
            pb="md"
            className={styles.joke}
        >
            <div className={`${styles.flip} ${answer ? styles.flipped : ''}`} style={{ height: `${height}px` }}>
                <div className={`${styles.card} ${styles.closed}`} ref={closedContainer}>
                    <Box bgColor="primary" className={styles.lead}>
                        <H3 align="center" padding={[0, 'lg']} color="white">{question}</H3>
                        <Box justify="center" pt="md">
                            <Btn
                                color="secondary"
                                bgColor="transparent"
                                outline
                                style={transformNone}
                                onClick={() => setAnswer(true)}
                            >
                                SEE PUNCHLINE
                            </Btn>
                        </Box>
                    </Box>
                </div>
                <div className={`${styles.card} ${styles.open}`} ref={openContainer}>
                    <Box bgColor="primary" className={styles.lead}>
                        <H3 font="leader" color="white" align="center" padding={[0, 'lg']}>{question}</H3>
                    </Box>
                    <div className={styles.punchline}>
                        <H3 align="center">
                            {correctAnswer}
                        </H3>
                    </div>
                </div>

                <div className={styles.badge}>
                    <Badge color={colors.secondary} />
                    <Type font="small" className={styles.badgeText}>
                        <span>DAILY</span>
                        <span>JOKE</span>
                    </Type>
                </div>
            </div>
        </Box>
    );
};

Joke.propTypes = {
    question: PropTypes.string,
    correctAnswer: PropTypes.string,
    onNext: PropTypes.func,
    slideTime: PropTypes.number,
};

export default Joke;
