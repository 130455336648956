import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import reactParse from 'html-react-parser';

import {
    Box,
    Grid,
    Container,
    H1,
    H2,
    H3,
    H4,
    P,
    ValidationForm,
} from '@saladbob/sassafras';

import DynamicField from '../DynamicField';

const Lander2ColBg = ({
    title,
    titleSize,
    subtitle,
    subtitleSize,
    formTitle,
    formTitleSize,
    formSubtitle,
    formSubtitleSize,
    image,
    optin,
    bottomText,
    currentStep,
    lastStep,
    fields,
    onSubmit,
    disabled,
    userData,
    imgPath,
    publicPath,
    loading,
}) => {
    const formRef = useRef();

    const submitForm = (e) => {
        e.preventDefault();
        formRef.current.validate();
    };

    return (
        <Box
            padding={[0, 'md']}
            bgImage={`${publicPath}${imgPath}${image}`}
            minHeight="100vh"
        >
            <Container
                margin="auto"
                size="1120px"
                padding={['xl', 0, 0, 0]}
                minHeight="100%"
                verticalAlign="center"
            >
                <Grid
                    width="100%"
                    gridGap="md"
                    columns={{ default: '1fr', laptop: '5fr 3fr' }}
                >
                    <Box verticalAlign="end">
                        <Box
                            bgColor="primary"
                            padding="md"
                            depth="lg"
                            rounded="lg"
                            bdColor="primary600"
                            bdWidth={['1rem', 0, 0, 0]}
                        >
                            {title && (
                                <H1
                                    font={{ phone: 'h3', laptop: titleSize || 'large' }}
                                    color="white"
                                >
                                    {reactParse(title)}
                                </H1>
                            )}
                            {subtitle && (
                                <H2
                                    font={{ phone: 'p', laptop: subtitleSize || 'h2' }}
                                    weight="normal"
                                    color="white"
                                >
                                    {reactParse(subtitle)}
                                </H2>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            bgColor="white"
                            padding="md"
                            depth="lg"
                            bdColor="secondary"
                            bdWidth={[0, 0, 0, '1rem']}
                            rounded="lg"
                        >
                            {formTitle && (
                                <H3
                                    font={{ default: 'h4', laptop: formTitleSize || 'h3' }}
                                    mb={formSubtitle ? 0 : 'md'}
                                    align="center"
                                >
                                    {reactParse(formTitle)}
                                </H3>
                            )}
                            {formSubtitle && (
                                <H4
                                    font={{ default: 'p', laptop: formSubtitleSize || 'leader' }}
                                    mb="md"
                                    align="center"
                                >
                                    {reactParse(formSubtitle)}
                                </H4>
                            )}
                            <ValidationForm ref={formRef} disabled={disabled} onSubmit={onSubmit}>
                                {fields
                                    && fields.map(({ hide, ...field }) => (
                                        <DynamicField
                                            key={field.name}
                                            submit={submitForm}
                                            userData={userData}
                                            hide={hide && lastStep <= currentStep}
                                            loading={loading}
                                            {...field}
                                        />
                                    ))}
                            </ValidationForm>
                            {optin && (
                                <P margin={['lg', 0]}>
                                    {reactParse(optin)}
                                </P>
                            )}
                            {bottomText && (
                                <P>
                                    {reactParse(bottomText)}
                                </P>
                            )}
                            <Box width="260px" margin={['lg', 'auto']}>
                                <img
                                    src={`${publicPath}${imgPath}ssl.svg`}
                                    style={{ width: '100%', display: 'bloxk' }}
                                    alt="WE USE 256-BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
};

Lander2ColBg.propTypes = {
    title: PropTypes.string,
    titleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    subtitle: PropTypes.string,
    subtitleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    formTitle: PropTypes.string,
    formTitleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    formSubtitle: PropTypes.string,
    formSubtitleSize: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    instructions: PropTypes.string,
    instructionsIcon: PropTypes.string,
    publicPath: PropTypes.string,
    imgPath: PropTypes.string,
    helpBox: PropTypes.object,
    image: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.object),
    optin: PropTypes.object,
    bottomText: PropTypes.string,
    style: PropTypes.string,
    bgImage: PropTypes.string,
    onSubmit: PropTypes.func,
    prevStep: PropTypes.func,
    currentStep: PropTypes.number,
    lastStep: PropTypes.number,
    stepCount: PropTypes.number,
    disabled: PropTypes.bool,
    userData: PropTypes.object,
};

Lander2ColBg.defaultProps = {
    publicPath: window.publicPath || 'public/',
    imgPath: window.imgPath || 'images/',
};

export default Lander2ColBg;
