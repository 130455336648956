import React from 'react';
import {
    Box,
    Grid,
    Type,
    P,
    H1,
    H2,
} from '@saladbob/sassafras';

const EmailVerify = ({
    em,
    // brandName = window.brandName,
    publicPath = window.publicPath || 'public/',
    imagePath = window.imagePath || 'images/',
    brandPath = window.brandPath || '',
}) => (
    <Box
        minHeight="calc(100vh - 160px)"
        width="100%"
        padding={{ phone: 'md', tablet: ['8vh', 'md', 'md', 'md'] }}
        verticalAlign="flex-start"
        align="center"
        bgColor="neutral50"
    >
        <Grid
            columns={{ phone: '1fr', tablet: '1fr 3fr' }}
            maxWidth="800px"
            depth="lg"
        >
            <Box bgColor="primary" txtColor="white" verticalAlign="space-between" padding="md" align="center">
                <P align="center" font="leader" mb="sm">
                    Thanks for choosing
                    <br />
                    <Type nowrap>TOP FINANCE LOAN!</Type>
                    {' '}
                </P>
                <Box maxWidth={{ phone: '80px', laptop: '100%' }}>
                    <img src={`${publicPath}${imagePath}${brandPath}email-verify.svg`} alt="Email Graphic" />
                </Box>
            </Box>
            <Box padding={{ phone: 'md', laptop: 'xl' }} verticalAlign="center" bgColor="white">
                <H1 mb={{ phone: 'xs', laptop: 'md' }} font={{ phone: 'h3', laptop: 'h1' }}>
                    Almost Done!
                </H1>
                <H2 mb={{ phone: 'xs', laptop: 'md' }} font={{ phone: 'leader', laptop: 'h2' }}>
                    For verification purposes, we sent an email to
                    {' '}
                    {em}
                    .
                </H2>
                <P font={{ phone: 'p', laptop: 'leader' }}>
                    In a new tab or window, please check your email and click the link to verify your email address and
                    finalize the application.
                </P>
                <P font={{ phone: 'p', laptop: 'leader' }}>
                    Don&apos;t see them email? Please check your spam folder.
                </P>
            </Box>
        </Grid>
    </Box>
);

export default EmailVerify;
