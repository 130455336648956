/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    FieldText,
    Spinner,
    Box,
    P,
} from '@saladbob/sassafras';

const getTheBankName = async (routing, api) => {
    const data = new FormData();
    data.append('pkey', 'PAufNMutiO4vqwQf');
    data.append('mode', 'get-bank');
    data.append('routing', routing);

    const bankName = await fetch(api, {
        method: 'POST',
        body: data,
    })
        .then((res) => res.json())
        .then((res) => res.msg);

    return bankName;
};

const AbaField = (props) => {
    const [bankName, setBankName] = useState();
    const [loading, setLoading] = useState(false);

    const findName = async (e) => {
        const { getBankName, bankNameApi } = props;
        const aba = e.target.value;

        if (aba.length === 9 && typeof getBankName === 'function') {
            setLoading(false);
            const bn = await getTheBankName(aba, bankNameApi);
            setBankName(bn);
            setLoading(false);
        } else {
            setBankName('');
            setLoading(false);
        }
    };

    return (
        <>
            <FieldText
                onInput={findName}
                type="tel"
                maxlength={9}
                minlength={9}
                {...props}
            />
            {loading && (
                <Box align="center" justify="center" margin={['md', 0]} txtColor="neutral">
                    <Spinner size="sm" color="neutral" align="center" margin="auto" />
                </Box>
            )}
            {bankName && (
                <P font="control" align="center">
                    {bankName}
                </P>
            )}
        </>
    );
};

AbaField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
};

export default AbaField;
