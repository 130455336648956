import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
} from '@saladbob/sassafras';

import { FormSteps, LanderContent } from '../components';

const Lander = ({
    step,
    lastStep,
    fields,
    userData,
    onSubmit,
    prevStep,
    onValidate,
    onError,
    onSkip,
    errMsg,
    loading,
    path,
    publicPath,
    imagePath,
}) => (
    <>
        <FormSteps
            fields={fields}
            onValidate={onValidate}
            onError={onError}
            onSubmit={onSubmit}
            userData={userData}
            prevStep={prevStep}
            onSkip={onSkip}
            step={step}
            lastStep={lastStep}
            publicPath={publicPath}
            path={path}
            imagePath={imagePath}
            loading={loading}
            errMsg={errMsg}
        />
        {step === 0
            && (
                <>
                    {fields.length
                        && fields[0].style !== 'lander2col'
                        && fields[0].style !== 'lander2colbg'
                        && fields[0].style !== 'page'
                        && (
                            <Box maxWidth="260px" margin={['lg', 'auto']}>
                                <img
                                    src="public/images/ssl.svg"
                                    style={{ width: '100%', display: 'bloxk' }}
                                    alt="WE USE 256-BIT SSL TECHNOLOGY TO ENCRYPT YOUR DATA"
                                />
                            </Box>
                        )}
                    <LanderContent />
                </>
            )}
    </>
);

Lander.propTypes = {
    step: PropTypes.number,
    lastStep: PropTypes.number,
    fields: PropTypes.arrayOf(PropTypes.object),
    onSubmit: PropTypes.func,
    prevStep: PropTypes.func,
    onValidate: PropTypes.func,
    onError: PropTypes.func,
    onSkip: PropTypes.func,
    userData: PropTypes.object,
    path: PropTypes.string,
    imagePath: PropTypes.string,
    publicPath: PropTypes.string,
    loading: PropTypes.bool,
    errMsg: PropTypes.string,
};

export default Lander;
